import plugin_vue3_A0OWXRrUgq from "/opt/build/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import payload_client_yVLowv6hDl from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import formater_dVUCR2gIAZ from "/opt/build/repo/plugins/formater.js";
export default [
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  chunk_reload_client_UciE0i6zes,
  payload_client_yVLowv6hDl,
  formater_dVUCR2gIAZ
]