export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"title","content":"Where Does European Democracy Support Funding Go?"},{"name":"description","content":"Explore funding data on who gives and receives European democracy support, with transparency on both donors and recipients, as well as categorised spending areas."},{"name":"keywords","content":"European Democracy Support, Funding Data, Donors and Recipients, Democracy Support Spending, European Institutions, European Donors, Democracy Support Categories, Transparency in Democracy Support, Democracy Support Areas"}],"link":[{"rel":"preconnect","href":"https://fonts.googleapis.com"},{"rel":"preconnect","href":"https://fonts.gstatic.com","crossorigin":true},{"href":"https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700","rel":"stylesheet"},{"href":"https://fonts.googleapis.com/css2?family=Noto+Serif:wght@400;600&display=swap","rel":"stylesheet"},{"rel":"icon","type":"image/x-icon","sizes":"32x32","href":"/cropped-favicon-32x32.png"}],"style":[],"script":[{"id":"cookieyes","type":"text/javascript","src":"https://cdn-cookieyes.com/client_data/2bc0d15e9c5cc8ca6461b5fe/script.js"},{"id":"googletagmanager","type":"text/javascript","src":"https://www.googletagmanager.com/gtag/js?id=G-BHNNDW48HC","async":true,"data-cookieyes":"cookieyes-analytics"}],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = false

export const devPagesDir = null

export const devRootDir = null