import { default as _91id_93RwjdiXkW6BMeta } from "/opt/build/repo/pages/bydonors/[id].vue?macro=true";
import { default as flowBIuPRKokdrMeta } from "/opt/build/repo/pages/flow.vue?macro=true";
import { default as geouoMbRXRGJzMeta } from "/opt/build/repo/pages/geo.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as mobilez50oEGgk70Meta } from "/opt/build/repo/pages/mobile.vue?macro=true";
import { default as _91id_93kdVial5TVJMeta } from "/opt/build/repo/pages/recipients/[id].vue?macro=true";
export default [
  {
    name: _91id_93RwjdiXkW6BMeta?.name ?? "bydonors-id",
    path: _91id_93RwjdiXkW6BMeta?.path ?? "/bydonors/:id()",
    meta: _91id_93RwjdiXkW6BMeta || {},
    alias: _91id_93RwjdiXkW6BMeta?.alias || [],
    redirect: _91id_93RwjdiXkW6BMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/bydonors/[id].vue").then(m => m.default || m)
  },
  {
    name: flowBIuPRKokdrMeta?.name ?? "flow",
    path: flowBIuPRKokdrMeta?.path ?? "/flow",
    meta: flowBIuPRKokdrMeta || {},
    alias: flowBIuPRKokdrMeta?.alias || [],
    redirect: flowBIuPRKokdrMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/flow.vue").then(m => m.default || m)
  },
  {
    name: geouoMbRXRGJzMeta?.name ?? "geo",
    path: geouoMbRXRGJzMeta?.path ?? "/geo",
    meta: geouoMbRXRGJzMeta || {},
    alias: geouoMbRXRGJzMeta?.alias || [],
    redirect: geouoMbRXRGJzMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/geo.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index",
    path: indexlaFeZuYZkUMeta?.path ?? "/",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: mobilez50oEGgk70Meta?.name ?? "mobile",
    path: mobilez50oEGgk70Meta?.path ?? "/mobile",
    meta: mobilez50oEGgk70Meta || {},
    alias: mobilez50oEGgk70Meta?.alias || [],
    redirect: mobilez50oEGgk70Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/mobile.vue").then(m => m.default || m)
  },
  {
    name: _91id_93kdVial5TVJMeta?.name ?? "recipients-id",
    path: _91id_93kdVial5TVJMeta?.path ?? "/recipients/:id()",
    meta: _91id_93kdVial5TVJMeta || {},
    alias: _91id_93kdVial5TVJMeta?.alias || [],
    redirect: _91id_93kdVial5TVJMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/recipients/[id].vue").then(m => m.default || m)
  }
]