import { formatLocale } from "d3";

export default defineNuxtPlugin(() => {
  const locale = formatLocale({
    decimal: ".",
    thousands: ",",
    grouping: [3],
    currency: ["$", "M"],
    minus: "\u2212",
    percent: "\u202F%",
  });

  const salaryFormater = (num) => {
    if (num < 1) return locale.format("$,.2~r")(num);
    else if (num < 10) return locale.format("$,.2~r")(num);
    else if (num < 100) return locale.format("$,.3~r")(num);
    else if (num < 1000) return locale.format("$,.3~r")(num);
    else return locale.format("$,.4~r")(num);
  };
  

  const decimalFormater = locale.format(".3f");
  //const salaryFormater = locale.format("$,.2r");
  const intFormater = locale.format(",.0f");
  const yearFormater = locale.format(".0f");
  const scientificFormater = locale.format(".3s");
   const expFormater = locale.format(".2e");

  return {
    provide: {
      formater: {
        decimalFormater,
        salaryFormater,
        intFormater,
        yearFormater,
        scientificFormater,
        expFormater,
      },
    },
  };
});
